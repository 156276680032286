import { Component, Input, OnInit } from '@angular/core';
import { Express } from '../../interfaces/express.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-express-mensaje',
  templateUrl: './express-mensaje.component.html',
  styleUrls: ['./express-mensaje.component.scss'],
})
export class ExpressMensajeComponent implements OnInit {

  constructor( private modalCtrl: ModalController) { }
  titulo = '';
  text: '';
  @Input()pexpress: Express;
  ngOnInit() {}

  enviar(){
    this.modalCtrl.dismiss({titulo: this.titulo, mensaje: this.text});
  }
}
