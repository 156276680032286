import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from '../../interfaces/empresa.interface';
import { ModalController } from '@ionic/angular';
import { GenaralDataService } from '../../services/genaral-data.service';
import { PrestashopService } from '../../services/prestashop.service';
import uniqid from 'uniqid';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
})
export class EmpresaComponent implements OnInit {
  titulo = 'Nueva empresa';
  @Input()empresa: Empresa;
  provincias: any[] = [];
  grupos: any[] = [];
  autovalidador = false;
  constructor( private modalCtrl: ModalController,
               private generalDataService: GenaralDataService,
               private psService: PrestashopService) { }

  async ngOnInit() {
    if (!this.empresa){
      this.empresa = {};
    } else {
      this.titulo = 'Edición de empresa ' + this.empresa.nombreFiscal;
    }
    this.grupos = await this.psService.getGrupos();
    
    const state: any = await this.generalDataService.getProvincias();
    console.log(state);
    if (state.ok){
      this.provincias = state.provincias;
    }
  }

  regenerarValidador(){
    this.autovalidador = true;
    const validador = uniqid.time();
    this.empresa.validador = validador;
  }

  async guardar(){
    console.log(this.empresa);
    this.modalCtrl.dismiss(this.empresa);
  }

}
