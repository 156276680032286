import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PromocionResp, Promocion } from '../interfaces/promocion.interface';
import { Preferencias } from '../interfaces/tarjeta.interface';

const URL = environment.bakendUrl;
@Injectable({
  providedIn: 'root'
})
export class PromocionesService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargarPromo(tipo: string){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
      this.http.get(`${URL}/promociones-activas/${tipo}`, {headers: this.header}).subscribe((resp: PromocionResp) => {
        resolve(resp);
      });
    });
  }

  async cargarPromoFiltrosActivos(tipo: string, sol: any){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
      this.http.put(`${URL}/filtros/promociones-activas/${tipo}`, sol, {headers: this.header}).subscribe((resp: PromocionResp) => {
        resolve(resp);
      });
    });
  }
  async cargarPromoFiltrosInactivos(tipo: string, sol: any){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
      this.http.put(`${URL}/filtros/promociones-inactivas/${tipo}`, sol, {headers: this.header}).subscribe((resp: PromocionResp) => {
        resolve(resp);
      });
    });
  }

  async cargarPromoInactivas(tipo: string){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
      this.http.get(`${URL}/promociones-inactivas/${tipo}`, {headers: this.header}).subscribe((resp: PromocionResp) => {
        resolve(resp);
      });
    });
  }
  async cargarPromopendientes(tipo: string, inicio: string){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
      this.http.put(`${URL}/filtros/promociones-pendientes/${tipo}`, {inicio}, {headers: this.header}).subscribe((resp: PromocionResp) => {
        resolve(resp);
      });
    });
  }
  async cargarPromoFinalizadas(tipo: string, vence: string){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
      this.http.put(`${URL}/filtros/promociones-finalizadas/${tipo}`, {vence}, {headers: this.header}).subscribe((resp: PromocionResp) => {
        resolve(resp);
      });
    });
  }

  async anadirPromo(promo: Promocion, tipo: string, preferencias?: Preferencias){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
        this.http.post(`${URL}/promociones`, {promo, tipo, preferencias}, {headers: this.header}).subscribe((resp: PromocionResp) => {
          resolve(resp);
        });
    });
  }

  async actualizaPromo(promo: Promocion){
    await this.cargarHeader();
    return new Promise<PromocionResp>(resolve => {
        this.http.put(`${URL}/promociones/${promo._id}`, promo, {headers: this.header}).subscribe((resp: PromocionResp) => {
          resolve(resp);
        });
    });
  }

}
