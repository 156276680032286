import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Cliente } from '../../interfaces/clientes.interface';
import { EmpresaComponent } from '../../modal/empresa/empresa.component';
import { Empresa } from '../../interfaces/empresa.interface';
import { GenaralDataService } from '../../services/genaral-data.service';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
})
export class ClienteComponent implements OnInit {
  size = 3;
  titulo = 'Nuevo cliente';
  empresa: Empresa;
  @Input() cliente: Cliente;
  provincias: any[] = [];
  constructor(private modalCtrl: ModalController, private generalDataService: GenaralDataService) { }

  async ngOnInit() {
    if (!this.cliente) {
      this.cliente = {};
    } else {
      this.titulo = 'Edición de cliente ' + this.cliente.nombre + ' ' + this.cliente.apellido1;
      if (this.cliente.empresa) {
        this.empresa = this.cliente.empresa;
      }
    }
    const state: any = await this.generalDataService.getProvincias();
    if (state.ok){
      this.provincias = state.provincias;
    }
  }
  formCliente() {    
    this.cliente.tiendaBusiness = false;
    this.modalCtrl.dismiss({ cliente: this.cliente, empresa: this.empresa });
  }
  async seleccionarEmpresa() {
    const modal = await this.modalCtrl.create({
      component: EmpresaComponent,
      cssClass: 'empresaClient-modal',
      backdropDismiss: false,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.empresa = data;
      this.cliente.empresa = data;
    }
  }

}
