import { Component, Input, OnInit } from '@angular/core';
import { ImagenComponent } from '../imagen/imagen.component';
import { ModalController, AlertController } from '@ionic/angular';
import { ArticuloSelectComponent } from '../articulo-select/articulo-select.component';
import { Pack, PArticulo, ArticuloPlan } from '../../interfaces/packs.inteface';
import { Articulo } from 'src/app/interfaces/articulos.inteface';
import { ArticuloService } from '../../services/articulo.service';
import { PackService } from '../../services/pack.service';

@Component({
  selector: 'app-pack',
  templateUrl: './pack.component.html',
  styleUrls: ['./pack.component.scss'],
})
export class PackComponent implements OnInit {

  @Input()pack: Pack;
  @Input()edita: boolean;
  articulos: ArticuloPlan[] = [];
  imagenes: any[] = [];
  imgSave: any[] = [];
  files: File[] = [];
  processing: boolean;
  uploadImage: string;
  titulo = 'Nevo pack';

  constructor( private modalCtrl: ModalController,
               private packService: PackService,
               private articuloService: ArticuloService) { }

  async ngOnInit() {
    if (!this.edita){
      this.pack = {};
      this.pack.articulos = [];
    } else {
      this.titulo = 'Edicion pack: ' + this.pack.cod;
      this.articulos = await this.obtenerArticulos(this.pack);
    }
  }

  presentActionSheet(fileLoader: { click: () => void; onchange: () => void; files: any[]; }) {
    fileLoader.click();
    const that = this;
    fileLoader.onchange = () => {
      const file = fileLoader.files[0];
      this.files.push(file);
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        that.processing = true;
        that.getOrientation(file, (orientation: number) => {
          if (orientation > 1) {
            that.resetOrientation(reader.result, orientation, (resetBase64Image: string) => {
              this.uploadImage = resetBase64Image;
            });
          } else {
            this.imagenes.push(String(reader.result));
          }
        });
      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
    };
  }

  getOrientation(file: Blob, callback: { (orientation: any): void; (arg0: number): any; }) {
    const reader = new FileReader();
    reader.onload = (e: any) => {

      const view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xFFD8) { return callback(-2); }
      const leg = view.byteLength;
      let offset = 2;
      while (offset < leg) {
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xFFE1) {
          if (view.getUint32(offset += 2, false) !== 0x45786966) { return callback(-1); }
          const little = view.getUint16(offset += 6, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
              return callback(view.getUint16(offset + (i * 12) + 8, little));
            }
          }
        }
        else if ((marker && 0xFF00) !== 0xFF00) { break; }
        else { offset += view.getUint16(offset, false); }
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file);
  }

  resetOrientation(srcBase64: string | ArrayBuffer, srcOrientation: number, callback:
    // tslint:disable-next-line: unified-signatures
    { (resetBase64Image: any): void; (arg0: string): void; }) {
    const img = new Image();

    img.onload = () => {
        // tslint:disable-next-line: one-variable-per-declaration
        const width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
        switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
        ctx.drawImage(img, 0, 0);

      // export base64
        callback(canvas.toDataURL());
    };

    img.src = String(srcBase64);
  }

  async verImagen(img: any, edita?: boolean){

    const modal = await this.modalCtrl.create({
      component: ImagenComponent,
      cssClass: 'articulo-modal',
      backdropDismiss: false,
      componentProps: {
        image: img,
        edita
      }
    });

    await modal.present();
  }

  async addArticulo(){
    const modal = await this.modalCtrl.create({
      component: ArticuloSelectComponent,
      cssClass: 'artSelect-modal',
      backdropDismiss: false,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
    let existe: boolean;
    this.pack.articulos.forEach( ar => {
      if (ar.articulo === data.articulo._id){
          ar.unidades += Number(data.unidades);
          existe = true;
        }
    });

    if (existe){
      this.articulos.forEach(ar => {
        if (ar.articulo._id === data.articulo._id){
          ar.unidades += Number(data.unidades);
        }
      });
    } else {
      const art = {
          articulo: data.articulo._id,
          unidades: Number(data.unidades)
        };
      const artPlan = {
          articulo: data.articulo,
          unidades: Number(data.unidades)
        };
      this.pack.articulos.push(art);
      this.articulos.push(artPlan);
      }
    }
  }

  private obtenerArticulos(pack: Pack){
    return new Promise<ArticuloPlan[]>(resolve => {
      const array: ArticuloPlan[] = [];
      pack.articulos.forEach(async a => {
        const resp = await this.articuloService.obtenerArticulo(a.articulo._id);
        array.push({articulo: resp.articulo, unidades: a.unidades});
      });
      resolve(array);
    });
  }

  async eliminaArt(art: ArticuloPlan){
    this.pack.articulos = this.pack.articulos.filter(a => {
      return a.articulo._id !== art.articulo._id;
    });
    this.articulos = await this.obtenerArticulos(this.pack);
  }

  async removeImagen(img: any, edita?: boolean){
    if (edita){
      this.pack.imagenes = this.pack.imagenes.filter(f => {
        return f !== img;
      });
    } else {
      this.imagenes = this.imagenes.filter( i => {
          return i !== img;
      });
    }
  }
  async guardar(){
    this.files.forEach(async f => {
      const resp = await this.articuloService.addImagen(f);
      if (!resp){
        throw new Error('Archivo no cargado');
      }
    });
    const res = await this.packService.nuevoPack(this.pack);
    this.modalCtrl.dismiss(res.ok);
  }
  async actualizar(){
    this.files.forEach(async f => {
      const resp = await this.articuloService.addImagen(f);
      if (!resp){
        throw new Error('Archivo no cargado');
      }
    });
    const res = await this.packService.actualizaPack(this.pack);
    this.modalCtrl.dismiss(res);
  }
}

