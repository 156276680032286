import { Component, OnInit } from '@angular/core';

import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PromoMenuComponent } from './components/promo-menu/promo-menu.component';
import { LoginService } from './services/login.service';
import { Global } from './global';
import { UiSereviceService } from './services/ui-serevice.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public selectedIndex = 0;
  loginAcces: boolean;
  public appPages = [
    {
      title: 'Inicio',
      url: '/inicio',
      icon: 'home'
    },
    /*{
      title: 'Pedidos empresas',
      url: '/pedidos',
      icon: 'cart'
    },*/
    {
      title: 'Pedidos express',
      url: '/pexpress',
      icon: 'train'
    },
    {
      title: 'Clientes',
      url: '/clientes',
      icon: 'people'
    },
    {
      title: 'Empresas',
      url: '/empresas',
      icon: 'business'
    },
    {
      title: 'Tarjetas fidelización',
      url: '/tarjetas',
      icon: 'card'
    },
    {
      title: 'Citas',
      url: '/citas',
      icon: 'calendar'
    },
    {
      title: 'Promos fidelización',
      url: '/promociones',
      icon: 'megaphone'
    },
    {
      title: 'Notificaciones',
      url: '/notificaciones',
      icon: 'send'
    },
    /*{
      title: 'Articulos',
      url: '/articulos',
      icon: 'pricetag'
    },
    {
      title: 'Packs',
      url: '/packs',
      icon: 'pricetags'
    },*/
    {
      title: 'Usuarios',
      url: '/usuarios',
      icon: 'person'
    },
    {
      title: 'Ajustes',
      url: '/ajustes',
      icon: 'construct'
    }
  ];

  loginUser = {
    user: '',
    pass: ''
  };

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private loginService: LoginService,
    private statusBar: StatusBar,
    private popoverCtrl: PopoverController,
    private ui: UiSereviceService,
    private navCtrl: NavController,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    const login: any = await this.loginService.verificaLogin();
    if (login.ok){
      this.loginAcces = true;
      Global.usuario = login.usuario;
    }
  }

  async promoMenu(event){
    const popover = await this.popoverCtrl.create({
      component: PromoMenuComponent,
      cssClass: 'my-custom-class',
      event,
      translucent: true
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    switch (data){
      case 'business':
        this.navCtrl.navigateForward(`/promociones/${data}`);
        break;
      case 'clientes':
        this.navCtrl.navigateForward(`/promociones/${data}`);
        break;
      case 'tarjeta':
        this.navCtrl.navigateForward(`/promociones/${data}`);
        break;
    }
  }
  async login(){
    const resp: any = await this.loginService.loginUser(this.loginUser.user, this.loginUser.pass);
    if (resp.ok){
      const respU: any = await this.loginService.verificaLogin();
      Global.usuario = respU.usuario;
      this.loginAcces = true;
    } else {
      this.ui.alertError('Usuario o contraseña incorrecta!');
      this.loginUser.user = '';
      this.loginUser.pass = '';
    }
  }
}

