import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tipo-cita',
  templateUrl: './tipo-cita.component.html',
  styleUrls: ['./tipo-cita.component.scss'],
})
export class TipoCitaComponent implements OnInit {

  tiposCita = ['CARDIO', 'NUTRI', 'TABACO', 'MENO', 'TELE'];

  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {}

  nombreCita(tipo: string){
    switch (tipo){
      case 'NUTRI':
        return 'Nutrición y Dietetica';
      case 'CARDIO':
        return 'Salud cardiovascular';
      case 'TABACO':
        return 'Deshabituación del Tabaco';
      case 'MENO':
        return 'Premenopausia y menopausia';
      case 'TELE':
        return 'Telederma';
    }
    }

    select(cita: string){
      this.modalCtrl.dismiss(cita);
    }
  }


