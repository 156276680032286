import { Component, Input, OnInit } from '@angular/core';
import { Pedido } from '../../interfaces/pedidos.interface';
import { ModalController } from '@ionic/angular';
import { Direccion } from '../../interfaces/direccion.interface';
import { ClienteService } from '../../services/cliente.service';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.scss'],
})
export class PedidoComponent implements OnInit {

  @Input() pedido: any;
  direccion: Direccion = {};
  constructor( private modalCtrl: ModalController,
               private clienteService: ClienteService,
               private ui: UiSereviceService) { }
  titulo: string;
  estadoModificado: boolean;

  ngOnInit() {
    this.titulo = `Pedido: ${this.pedido.idPedido}`;
    this.cargarDireccion();
  }

  cambioEstado(){
    this.estadoModificado = true;
  }

  async cargarDireccion(){
    const dir: any = await this.clienteService.getDireccion(this.pedido.direccion);
    console.log(dir);
    if (!dir.ok){
      this.ui.alertError(dir.error);
      return;
    }
    this.direccion = dir.direccion;
  }

  guardar() {
    this.modalCtrl.dismiss({pedido: this.pedido, estadoModificado: this.estadoModificado});
  }

}
