import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styleUrls: ['./estados.component.scss'],
})
export class EstadosComponent implements OnInit {

  constructor( private modalCtrl: ModalController) { }
  @Input()expres: boolean;

  estados = ['NUEVO', 'PROCESO', 'REPARTO', 'CANCELADO', 'ENTREGADO'];

  ngOnInit() {
    if (this.expres){
      this.estados = ['NUEVO', 'PROCESO', 'PREPARADO', 'CANCELADO', 'RETIRADO'];
    }
  }

  select(estado){
    this.modalCtrl.dismiss(estado);
  }

}
