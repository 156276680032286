import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chart.js';

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.scss'],
})
export class GraficoComponent implements OnInit {
  @Input() dataS: any[];
  @Input() label: string;
  constructor() { }

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  public lineChartColors: Color[] = [];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  async ngOnInit() {
    if (this.dataS.length > 0) {
      const lna = { data: [], label: this.label };
      const label: Label[] = [];
      let lnb: any;
      if (this.dataS[0].dia) {
        lnb = {
          data: [],
          label: 'DIA'
        };
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.dataS.length; i++) {
        const d = this.dataS[i];
        const fecha = new Date(d.fecha);
        label.unshift(`${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`);
        if (d.peso) {
          lna.data.unshift(d.peso);
        }
        if (d.azucar) {
          lna.data.unshift(d.azucar);
         }
        if (d.colesterol) {
          lna.data.unshift(d.colesterol);
        }
        if (d.dia) {
          lna.data.unshift(d.sys);
          lnb.data.unshift(d.dia);
        }
        if (i > 4) {
          i = this.dataS.length;
        }
      }
      this.lineChartData.push(lna);
      if (lnb){
        this.lineChartData.push(lnb);
      }
      this.lineChartLabels = label;
    }
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.length; i++) {
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        this.lineChartData[i].data[j] = this.generateNumber(i);
      }
    }
    this.chart.update();
  }

  private generateNumber(i: number): number {
    return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

  public pushOne(dat: any): void {
    const fecha = new Date();
    if (dat.dia){
      this.lineChartData[0].data.push(dat.sys);
      this.lineChartData[1].data.push(dat.dia);
    } else {
      this.lineChartData[0].data.push(dat);
    }
    this.lineChartLabels.push(`${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`);
  }

  public changeColor(): void {
    this.lineChartColors[2].borderColor = 'green';
    this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  }

  public changeLabel(): void {
    this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  }
}
