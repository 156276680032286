import { Pipe, PipeTransform } from '@angular/core';
import { Tarjeta } from '../interfaces/tarjeta.interface';

@Pipe({
  name: 'tarjeta'
})
export class TarjetaPipe implements PipeTransform {

  transform(tarjetas: Tarjeta[], txt: string): Tarjeta[] {
    let trj: Tarjeta[];
    if (txt){
      txt = txt.toLowerCase();
      trj =  tarjetas.filter(f => {
        return f.tarjeta.toLowerCase().includes(txt);
      });
      return trj;
    } else {
      return tarjetas;
    }
  }

}
