import { Component, OnInit } from '@angular/core';
import { ArticuloService } from '../../services/articulo.service';
import { ModalController, AlertController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { Articulo } from 'src/app/interfaces/articulos.inteface';


@Component({
  selector: 'app-articulo-select',
  templateUrl: './articulo-select.component.html',
  styleUrls: ['./articulo-select.component.scss'],
})
export class ArticuloSelectComponent implements OnInit {

  articulos: Articulo[] = [];
  buscarTxt: string;
  constructor(private articuloService: ArticuloService,
              private modalCtrl: ModalController,
              private ui: UiSereviceService,
              private alertController: AlertController) { }


  async ngOnInit() {
    const resp = await this.articuloService.cargartArticulos();
    if (resp.ok) {
      this.articulos = resp.articulos;
    } else {
      this.ui.alertError(resp.error);
    }
  }

  buscar(event) {
    this.buscarTxt = event.detail.value;
  }

  async select(art: Articulo) {
    const alert = await this.alertController.create({
      header: 'Unidades de ' + art.cod,
      inputs: [
        {
          name: 'und',
          type: 'number',
          min: 0,
        }
      ],
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log(data);
            this.modalCtrl.dismiss({articulo: art, unidades: data.und});
          }
        }
      ]
    });
    await alert.present();
  }

}

