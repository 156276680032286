import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Global } from '../global';

const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  loginUser(email: string, pass: string){
    return new Promise(async (resolve) => {

      await this.cargarHeader();
      if (this.header.token){
        resolve(true);
      }

      this.http.post(`${URL}/usuarios/login`, {email, password: pass}, {}).subscribe( async (login: any) => {
        if (!login.ok){
          resolve(false);
        }
        await this.storage.set('token', login.token);
        this.header.token = login.token;
        resolve(login);
      });
    });
  }

  async verificaLogin(){
    await this.cargarHeader();

    return new Promise(resolve => {
      if (this.header.token) {
        this.http.get(`${URL}/usuarios/validar`, {headers: this.header}).subscribe((resp: any) => {
          resolve(resp);
        });
      }
    });

  }

  loguout(){
    this.header.token = null;
    return new Promise(resolve => {
        this.storage.clear().then( c => {
          resolve(true);
        }).catch( r => {
          resolve(false);
        });

    });

  }

}
