import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit {
  @Input()texto: string;
  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {
    if (!this.texto){
      this.texto = '';
    }
  }

  guardar(){
    this.modalCtrl.dismiss(this.texto);
  }

}
