import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuscarEmpresaPipe } from './buscar-empresa.pipe';
import { ImagenPipe } from './imagen.pipe';
import { ClientePipe } from './cliente.pipe';
import { ArticuloPipe } from './articulo.pipe';
import { TarjetaPipe } from './tarjeta.pipe';
import { PromocionPipe } from './promocion.pipe';
import { PackPipe } from './pack.pipe';
import { UsuarioPipe } from './usuario.pipe';



@NgModule({
  declarations: [
                  BuscarEmpresaPipe,
                  ImagenPipe,
                  ClientePipe,
                  ArticuloPipe,
                  TarjetaPipe,
                  PromocionPipe,
                  PackPipe,
                  UsuarioPipe
                ],
  imports: [
    CommonModule
  ],
  exports: [
            BuscarEmpresaPipe,
            ImagenPipe,
            ClientePipe,
            ArticuloPipe,
            TarjetaPipe,
            PromocionPipe,
            PackPipe,
            UsuarioPipe
          ]
})
export class PipesModule { }
