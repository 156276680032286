import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-promo-menu',
  templateUrl: './promo-menu.component.html',
  styleUrls: ['./promo-menu.component.scss'],
})
export class PromoMenuComponent implements OnInit {

  constructor(private popoverCrl: PopoverController) { }

  ngOnInit() {}

  select(tipo: string){
      this.popoverCrl.dismiss(tipo);
  }

}
