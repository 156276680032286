import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { EmpresaService } from '../../services/empresa.service';
import { Empresa } from '../../interfaces/empresa.interface';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
})
export class EmpresaComponent implements OnInit {

  empresas: Empresa[] = [];
  buscarTxt = '';

  constructor( private modalCtrl: ModalController,
               private ui: UiSereviceService,
               private empresaService: EmpresaService) { }

  async ngOnInit() {
    const resp = await this.empresaService.cargarEmpresas();
    if (resp.ok){
      this.empresas = resp.empresas;
    } else {
      this.ui.alertError(resp.error);
    }
  }

  buscar(event){
    this.buscarTxt = event.detail.value;
  }

  select(empresa: Empresa){
    this.modalCtrl.dismiss(empresa);
  }

}
