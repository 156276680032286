import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit {

  lopd = false;

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  onClickAceptar() {

    this.modalController.dismiss({
      lopd: true
    });
  }

  onClickRechazar() {
    this.modalController.dismiss({
      lopd: false
    });
  }

}
