import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Mensaje, Express } from '../interfaces/express.interface';
import { Pedido } from '../interfaces/pedidos.interface';

const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader() {
    this.header.token = await this.storage.get('token');
  }

  async sernMensajeEmail(mensaje: Mensaje, idCliente: string, idPedido: string){
    await this.cargarHeader();
    return new Promise(resolve => {
      this.http.post(`${URL}/sendmailUserMensaje`, {mensaje, idCliente, idPedido}, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async sernMensajeEmailEstado(pedido: Express){
    await this.cargarHeader();
    return new Promise(resolve => {
      this.http.post(`${URL}/sendmailUserPedidoExEstado`, {pedido}, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async sernMensajeEmailEstadoPedido(pedido: Pedido){
    await this.cargarHeader();
    return new Promise(resolve => {
      this.http.post(`${URL}/sendmailUserPedidoEstado`, {pedido}, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
}
