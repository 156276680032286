import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiSereviceService {

  constructor(private alertCrtl: AlertController,
              private toastController: ToastController) { }

  async alertError(mensaje: string) {
    const alert = await this.alertCrtl.create({
      header: 'Error',
      message: mensaje,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    alert.present();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  validaMaestro() {
    return new Promise<boolean>(async resolve => {
      const alert = await this.alertCrtl.create({
        header: 'Autorizar operación',
        message: 'Ingrese la contraseña maestra de la app',
        inputs: [
          {
            name: 'pass',
            type: 'password',
            placeholder: 'Contraseña maestra',
            cssClass: 'specialClass',
          }
        ],
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          }, {
            text: 'SI',
            handler: (data) => {
              if (data.pass === 'Sana@pp2021Farma' ){
                resolve(true);
              } else {
                resolve(false);
              }
            }
          }
        ]
      });
      alert.present();

    });
  }

}
