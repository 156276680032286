import { Articulo, ArticuloResp } from './../interfaces/articulos.inteface';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const URL = environment.bakendUrl;


@Injectable({
  providedIn: 'root'
})
export class ArticuloService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargartArticulos(){
    await this.cargarHeader();
    return new Promise<ArticuloResp>(resolve => {
      this.http.get(`${URL}/articulos`, {headers: this.header}).subscribe((resp: ArticuloResp) => {
        resolve(resp);
      });
    });
  }

  async nuevoArticulo(art: Articulo){
    await this.cargarHeader();
    return new Promise<ArticuloResp>(resolve => {
      this.http.post(`${URL}/articulos`, art, {headers: this.header}).subscribe((resp: ArticuloResp) => {
        resolve(resp);
      });
    });
  }

  async addImagen(file: File){
    await this.cargarHeader();
    const formData: FormData = new FormData();
    formData.append('image', file, file.name);
    return new Promise((resolve) => {
      this.http.post(`${URL}/upload/prodimage`, formData, { headers: this.header }).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }

  async obtenerImagenes(img: string){
    await this.cargarHeader();
    return new Promise<File>(resolve => {
      this.http.get(`${URL}/download/articulo/${img}`, {headers: this.header}).subscribe((resp: File) => {
        resolve(resp);
      });
    });
  }

  async actualizarArticulo(art: Articulo) {
    await this.cargarHeader();
    return new Promise<ArticuloResp>(resolve => {
      this.http.put(`${URL}/articulos/${art._id}`, art, {headers: this.header}).subscribe((resp: ArticuloResp) => {
        resolve(resp);
      });
    });
  }

  async eliminaImagen(img: string){
    await this.cargarHeader();
    return new Promise<boolean>(resolve => {
      this.http.put(`${URL}/delete/imagen/${img}`, {}, {headers: this.header}).subscribe((resp: boolean) => {
        resolve(resp);
      });
    });
  }

  async eliminarArticulo(art: string){
    await this.cargarHeader();
    return new Promise<ArticuloResp>(resolve => {
      this.http.delete(`${URL}/articulo/${art}`, {headers: this.header}).subscribe((resp: ArticuloResp) => {
        resolve(resp);
      });
    });
  }

  async obtenerArticulo(artId: string){
    await this.cargarHeader();
    return new Promise<ArticuloResp>(resolve => {
      this.http.get(`${URL}/articulos/${artId}`, {headers: this.header}).subscribe((resp: ArticuloResp) => {
        resolve(resp);
      });
    });
  }

}
