import { Component, OnInit } from '@angular/core';
import { Tarjeta } from '../../interfaces/tarjeta.interface';
import { Cliente } from '../../interfaces/clientes.interface';
import { ModalController } from '@ionic/angular';
import { ClienteComponent } from '../../modal/cliente/cliente.component';
import { TarjetaService } from '../../services/tarjeta.service';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.scss'],
})
export class TarjetaComponent implements OnInit {

  tarjeta: Tarjeta = {preferencias: {interes: {}, familia: {}, porque: {}}};
  cliente: Cliente = {};
  constructor( private modalCtrl: ModalController, private tarjetaService: TarjetaService, private ui: UiSereviceService) { }

  ngOnInit() {}

  async selecionaCliente(){
    const modal = await this.modalCtrl.create({
      component: ClienteComponent,
      cssClass: 'cliente2-modal',
      backdropDismiss: false,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      const c = await this.tarjetaService.cargarTarjetaUsuario(data._id);
      if (!c.ok){
        this.cliente = data;
        this.tarjeta.cliente = data._id;
      } else {
        this.ui.alertError('El cliente ya dispone de tarejta');
      }
    }
  }
  valido(): boolean{
      return true && this.validaInteres() && this.validaFamilia() && this.validaPorQue()
      && this.cliente._id !== undefined && this.tarjeta.tarjeta !== undefined;
  }

  validaInteres(): boolean {
    return (this.tarjeta.preferencias.interes.analitica || this.tarjeta.preferencias.interes.bebe ||
      this.tarjeta.preferencias.interes.bucal ||
      this.tarjeta.preferencias.interes.dietetica || this.tarjeta.preferencias.interes.estetica
      || this.tarjeta.preferencias.interes.homeopatia || this.tarjeta.preferencias.interes.nutricion ||
      this.tarjeta.preferencias.interes.otros || this.tarjeta.preferencias.interes.sexual);
  }

  validaFamilia(){
    return (this.tarjeta.preferencias.familia.no_hijos || this.tarjeta.preferencias.familia.menor_3 ||
      this.tarjeta.preferencias.familia.menor_14 || this.tarjeta.preferencias.familia.menor_10 ||
      this.tarjeta.preferencias.familia.mayor_14);
  }

  validaPorQue(){
    return (this.tarjeta.preferencias.porque.casa || this.tarjeta.preferencias.porque.centro ||
      this.tarjeta.preferencias.porque.fidelidad || this.tarjeta.preferencias.porque.horario ||
      this.tarjeta.preferencias.porque.metromar || this.tarjeta.preferencias.porque.precios ||
      this.tarjeta.preferencias.porque.productos || this.tarjeta.preferencias.porque.trabajo);
  }
  async solicitar(){
    this.modalCtrl.dismiss(this.tarjeta);
  }

}
