import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ExpressService } from '../../services/express.service';
import { Express } from '../../interfaces/express.interface';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-clientenrc',
  templateUrl: './clientenrc.component.html',
  styleUrls: ['./clientenrc.component.scss'],
})
export class ClientenrcComponent implements OnInit {

  constructor(private modalCtrl: ModalController,
    private expressService: ExpressService,
    private ui: UiSereviceService) { }

  clientes: Express[] = [];
  buscarTxt = '';

  async ngOnInit() {
    const resp = await this.expressService.cargarClientesExpress();
    if (resp.ok){
      this.clientes = resp.express;
    } else {
      this.ui.alertError(resp.error);
    }
  }

  buscar(event){
    this.buscarTxt = event.detail.value;
  }
  select(cliente: any){
    this.modalCtrl.dismiss(cliente.nombreCliente);
  }

}
