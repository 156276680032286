
import { Pipe, PipeTransform } from '@angular/core';
import { Articulo } from '../interfaces/articulos.inteface';


@Pipe({
  name: 'articulo'
})
export class ArticuloPipe implements PipeTransform {

  transform(articulos: Articulo[], text: string): Articulo[] {
    let artPipe: Articulo[];
    if (!text) {
      return articulos;
    } else {
      text = text.toLowerCase();
      artPipe =  articulos.filter( art => {
        return art.cod.toLowerCase().includes(text) ||
          art.nombre.toLowerCase().includes(text);
      });
      return artPipe;
    }
  }
}
