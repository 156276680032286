import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { AzucarResp, Azucar, Peso, PesoResp, ColesterolResp, Colesterol, TensionResp, Tension } from '../interfaces/salud.interface';

const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class SaludService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader() {
    this.header.token = await this.storage.get('token');
  }

  async obtenerSaludAzucar(clienteId: string){
    await this.cargarHeader();
    return new Promise<AzucarResp>(resolve => {
        this.http.get(`${URL}/cliente/azucar/${clienteId}`, {headers: this.header}).subscribe((resp: AzucarResp) => {
          resolve(resp);
        });
    });
  }

  async nuevoAzucar(clienteId: string, azucar: Azucar){
    await this.cargarHeader();
    return new Promise<AzucarResp>(resolve => {
      this.http.post(`${URL}/cliente/azucar/${clienteId}`, {azucar}, {headers: this.header}).subscribe((resp: AzucarResp) => {
        resolve(resp);
      });
    });
  }

  async obtenerSaludPeso(clienteId: string){
    await this.cargarHeader();
    return new Promise<PesoResp>(resolve => {
        this.http.get(`${URL}/cliente/peso/${clienteId}`, {headers: this.header}).subscribe((resp: PesoResp) => {
          resolve(resp);
        });
    });
  }

  async nuevoPeso(clienteId: string, peso: Peso){
    await this.cargarHeader();
    return new Promise<PesoResp>(resolve => {
      this.http.post(`${URL}/cliente/peso/${clienteId}`, {peso}, {headers: this.header}).subscribe((resp: PesoResp) => {
        resolve(resp);
      });
    });
  }

  async obtenerSaludColesterol(clienteId: string){
    await this.cargarHeader();
    return new Promise<ColesterolResp>(resolve => {
        this.http.get(`${URL}/cliente/colesterol/${clienteId}`, {headers: this.header}).subscribe((resp: ColesterolResp) => {
          resolve(resp);
        });
    });
  }

  async nuevoColesterol(clienteId: string, colesterol: Colesterol){
    await this.cargarHeader();
    return new Promise<ColesterolResp>(resolve => {
      this.http.post(`${URL}/cliente/colesterol/${clienteId}`, {colesterol}, {headers: this.header}).subscribe((resp: ColesterolResp) => {
        resolve(resp);
      });
    });
  }

  async obtenerSaludTension(clienteId: string){
    await this.cargarHeader();
    return new Promise<TensionResp>(resolve => {
        this.http.get(`${URL}/cliente/tension/${clienteId}`, {headers: this.header}).subscribe((resp: TensionResp) => {
          resolve(resp);
        });
    });
  }

  async nuevoTension(clienteId: string, tension: Tension){
    await this.cargarHeader();
    console.log(tension);
    return new Promise<TensionResp>(resolve => {
      this.http.post(`${URL}/cliente/tension/${clienteId}`, tension, {headers: this.header}).subscribe((resp: TensionResp) => {
        resolve(resp);
      });
    });
  }
}
