import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rechazo',
  templateUrl: './rechazo.component.html',
  styleUrls: ['./rechazo.component.scss'],
})
export class RechazoComponent implements OnInit {
  @Input()comentario: string;
  constructor() { }

  ngOnInit() {}

}
