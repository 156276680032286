import { Component, Input, OnInit } from '@angular/core';
import { Usuario } from '../../interfaces/usuario.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class UsuarioComponent implements OnInit {

  @Input()usuario: Usuario;
  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {
    if (!this.usuario){
      this.usuario = {};
    }
  }
  formUsuario(){
    this.modalCtrl.dismiss(this.usuario);
  }

}
