import { Pipe, PipeTransform } from '@angular/core';
import { Cliente } from '../interfaces/clientes.interface';

@Pipe({
  name: 'cliente'
})
export class ClientePipe implements PipeTransform {

  transform(clientes: Cliente[], text: string): Cliente[] {
    if (text) { text = text.toLowerCase(); }
    if (!text) {
      return clientes;
    } else {
      return clientes.filter(cli => {
        return cli.nombre.toLowerCase().includes(text) ||
          cli.apellido1.toLowerCase().includes(text) ||
          cli.apellido2?.toLowerCase().includes(text) ||
          cli.direccion?.toLowerCase().includes(text) ||
          cli.localidad?.toLowerCase().includes(text) ||
          cli.email.toLowerCase().includes(text) ||
          cli.cp?.toString().includes(text) ||
          cli.nif.toUpperCase().includes(text) ||
          cli.telefono.toString().includes(text);
      });
    }
  }
}
