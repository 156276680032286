import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TarjetaResp, Tarjeta } from '../interfaces/tarjeta.interface';

const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class TarjetaService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargarTarjetasActivas(){
    await this.cargarHeader();
    return new Promise<TarjetaResp>(resolve => {
      this.http.get(`${URL}/tarjeta-activa`, {headers: this.header}).subscribe((resp: TarjetaResp) => {
        resolve(resp);
      });
    });
  }

  async cargarTarjetasPendientes(){
    await this.cargarHeader();
    return new Promise<TarjetaResp>(resolve => {
      this.http.get(`${URL}/tarjeta-pendiente`, {headers: this.header}).subscribe((resp: TarjetaResp) => {
        resolve(resp);
      });
    });
  }

  async activarTarjeta(tj: Tarjeta){
    await this.cargarHeader();
    return new Promise<TarjetaResp>(resolve => {
      this.http.put(`${URL}/tarjeta/${tj._id}`, tj, {headers: this.header}).subscribe((resp: TarjetaResp) => {
        resolve(resp);
      });
    });
  }

  async cargarTarjetaUsuario(idCliente: string){
    await this.cargarHeader();
    return new Promise<TarjetaResp>(resolve => {
      this.http.get(`${URL}/tarjeta/cliente/${idCliente}`, {headers: this.header}).subscribe((resp: TarjetaResp) => {
          resolve(resp);
      });
    });
  }

  async tarjetaSaldos(tarjetaId: string, saldo: any){
    await this.cargarHeader();
    return new Promise(resolve => {
      this.http.put(`${URL}/tarjeta/saldo/${tarjetaId}`, saldo, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  async crearTarjeta(tarjeta: Tarjeta){
    await this.cargarHeader();
    return new Promise<TarjetaResp>(resolve => {
      this.http.post(`${URL}/tarjeta-app/cliente/${tarjeta.cliente}`, tarjeta, {headers: this.header}).subscribe((resp: TarjetaResp) => {
        resolve(resp);
      });
    });
  }


  async eliminaTarjeta(tj: Tarjeta){
    await this.cargarHeader();
    return new Promise<TarjetaResp>(resolve => {
      this.http.delete(`${URL}/tarjeta/${tj._id}`, {headers: this.header}).subscribe((resp: TarjetaResp) => {
        resolve(resp);
      });
    });
  }

}
