import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ClienteResp, Cliente } from '../interfaces/clientes.interface';
import { PrestashopService } from './prestashop.service';
import { DireccionResp } from '../interfaces/direccion.interface';
import { EmpresaService } from './empresa.service';

const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  constructor(private storage: Storage,
              private http: HttpClient,
              private empresaService: EmpresaService,
              private psService: PrestashopService) { }

  page: number = 0;

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargarClientes(estado: string){
    this.page++;
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes?pag=${this.page}&estado=${estado}`, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async buscarClientes(text: string){
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes/buscar/${text}`, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async filtroCliente(idEmpresa: string){
    this.page++;
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes/empresa/${idEmpresa}?pag=${this.page}`, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async nuevoCliente(cliente: Cliente, empresa?: string){    
    await this.cargarHeader();
    if (empresa && (!cliente.direccion && !cliente.localidad && !cliente.provincia && ! cliente.cp)){
      const emp = await this.empresaService.cargarEmpresa(empresa['_id']);
      if(emp.ok){      
          cliente.direccion = emp.empresa.direccion;
          cliente.localidad = emp.empresa.localidad;
          cliente.provincia = emp.empresa.provincia;
          cliente.cp = emp.empresa.cp;
      }
    }
    return new Promise<ClienteResp>(resolve => {
        this.http.post(`${URL}/clientes-app`, {cliente, empresa}, {headers: this.header}).subscribe(async (resp: ClienteResp) => {
          console.log(resp);
          resolve(resp); 
        });
    });
  }

  async actualizaCliente(cliente: Cliente){
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.put(`${URL}/clientes/${cliente._id}`, cliente, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async getCliente(id: string){
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes/${id}`, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async eliminaCliente(cliente: Cliente){
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.put(`${URL}/clientes/disable/${cliente._id}`, {}, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async getDireccion(id: string){
    await this.cargarHeader();
    return new Promise<DireccionResp>(resolve => {
        this.http.get(`${URL}/cliente-direccion/${id}`, {headers: this.header}).subscribe((resp: DireccionResp) => {
          resolve(resp);
        });
      });
    }
    
  async reSendMail(cli: Cliente) {
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes/resendmail/${cli._id}`, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async validarCliente(cli: Cliente){
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/validar/clienteApp?validador=${cli._id}`, {headers: this.header}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }


  }
