import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class GenaralDataService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  getProvincias(){  
    return new Promise(async resolve => {
      await this.cargarHeader();
      this.http.get(`${URL}/ps_state`).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  getProvincia(id_state: string){
    return new Promise(async resolve => {
      await this.cargarHeader();
      this.http.get(`${URL}/ps_state/${id_state}`).subscribe(resp => {
        resolve(resp);
      });
    });
  }
}
