import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EmpresaResp, Empresa } from '../interfaces/empresa.interface';


const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargarEmpresas() {
    await this.cargarHeader();
    return new Promise<EmpresaResp>(resolve => {
      this.http.get(`${URL}/empresas`, {headers: this.header}).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

  async cargarEmpresa(empId: string) {
    await this.cargarHeader();
    return new Promise<EmpresaResp>(resolve => {
      this.http.get(`${URL}/empresas/${empId}`, {headers: this.header}).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

  async nuevaEmpresa(empresa: Empresa){
    await this.cargarHeader();
    return new Promise<EmpresaResp>(resolve => {
      this.http.post(`${URL}/empresas-app`, empresa, {headers: this.header}).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

  async actualizaEmpresa(empresa: Empresa){
    await this.cargarHeader();
    return new Promise<EmpresaResp>(resolve => {
      this.http.put(`${URL}/empresas/${empresa._id}`, empresa, {headers: this.header}).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

  async eliminaEmpresa(empId: string){
    await this.cargarHeader();
    return new Promise<EmpresaResp>(resolve => {
      this.http.put(`${URL}/empresas/disable/${empId}`, {}, {headers: this.header}).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

}
