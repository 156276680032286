import { Component, Input, OnInit } from '@angular/core';
import { Preferencias } from '../../interfaces/tarjeta.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-preferencias-promo',
  templateUrl: './preferencias-promo.component.html',
  styleUrls: ['./preferencias-promo.component.scss'],
})
export class PreferenciasPromoComponent implements OnInit {

  @Input()preferencias: Preferencias;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.preferencias.interes.analitica = false;
    this.preferencias.interes.bebe = false;
    this.preferencias.interes.bucal = false;
    this.preferencias.interes.dietetica = false;
    this.preferencias.interes.estetica = false;
    this.preferencias.interes.homeopatia = false;
    this.preferencias.interes.nutricion = false;
    this.preferencias.interes.otros = false;
    this.preferencias.interes.sexual = false;
    this.preferencias.familia.no_hijos = false;
    this.preferencias.familia.menor_3 = false;
    this.preferencias.familia.menor_14 = false;
    this.preferencias.familia.menor_10 = false;
    this.preferencias.familia.mayor_14 = false;
  }

  valido(): boolean {
    return true && this.validaInteres() || this.validaFamilia();
  }

  validaInteres(): boolean {
    return (this.preferencias.interes.analitica || this.preferencias.interes.bebe ||
      this.preferencias.interes.bucal ||
      this.preferencias.interes.dietetica || this.preferencias.interes.estetica
      || this.preferencias.interes.homeopatia || this.preferencias.interes.nutricion ||
      this.preferencias.interes.otros || this.preferencias.interes.sexual);
  }

  validaFamilia() {
    return (this.preferencias.familia.no_hijos || this.preferencias.familia.menor_3 ||
      this.preferencias.familia.menor_14 || this.preferencias.familia.menor_10 ||
      this.preferencias.familia.mayor_14);
  }

  solicitar(){
    this.modalCtrl.dismiss(this.preferencias);
  }

}
