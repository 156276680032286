import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Usuario, UsuarioResp } from '../interfaces/usuario.interface';

const URL = environment.bakendUrl;
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargarUsuarios(){
    await this.cargarHeader();
    return new Promise<UsuarioResp>(resolve => {
      this.http.get(`${URL}/usuarios`, {headers: this.header}).subscribe((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }

  async nuevousUario(usuario: Usuario){
    await this.cargarHeader();
    return new Promise<UsuarioResp>(resolve => {
      this.http.post(`${URL}/usuarios`, usuario, {headers: this.header} ).subscribe((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }
  async actualizaUario(usuario: Usuario){
    await this.cargarHeader();
    return new Promise<UsuarioResp>(resolve => {
      this.http.put(`${URL}/usuarios/${usuario._id}`, usuario, {headers: this.header} ).subscribe((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }

  async eliminaUsuario(usuario: Usuario){
    await this.cargarHeader();
    return new Promise<UsuarioResp>(resolve => {
      this.http.delete(`${URL}/usuarios/${usuario._id}`, {headers: this.header}).subscribe((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }
}
