import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-fecha-cita',
  templateUrl: './fecha-cita.component.html',
  styleUrls: ['./fecha-cita.component.scss'],
})
export class FechaCitaComponent implements OnInit {
  fecha: Date;
  hora: Date;
  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {}

  aceptar(){
    console.log(this.fecha);
    const date = new Date(`${this.fecha}T${this.hora}+01:00`);
    this.modalCtrl.dismiss(date);
  }
}
