import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';
import { HeaderModalComponent } from './header-modal/header-modal.component';
import { FechaCitaComponent } from './fecha-cita/fecha-cita.component';
import { FormsModule } from '@angular/forms';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { QuillModule } from 'ngx-quill';
import { SaludComponent } from './salud/salud.component';
import { ChartsModule } from 'ng2-charts';
import { GraficoComponent } from './grafico/grafico.component';
import { ClienteComponent } from './cliente/cliente.component';
import { LegalComponent } from './legal/legal.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { RechazoComponent } from './rechazo/rechazo.component';
import { TarjetaComponent } from './tarjeta/tarjeta.component';
import { PromoMenuComponent } from './promo-menu/promo-menu.component';
import { PreferenciasPromoComponent } from './preferencias-promo/preferencias-promo.component';



const modules = [
                  HeaderComponent,
                  FooterComponent,
                  HeaderModalComponent,
                  FechaCitaComponent,
                  TextEditorComponent,
                  SaludComponent,
                  GraficoComponent,
                  ClienteComponent,
                  LegalComponent,
                  EmpresaComponent,
                  RechazoComponent,
                  TarjetaComponent,
                  PromoMenuComponent,
                  PreferenciasPromoComponent
                ];

@NgModule({
  declarations: modules,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ChartsModule,
    QuillModule.forRoot()
  ],
  exports: modules
})
export class ComponentsModule { }
