import { ComponentsModule } from './../components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AjusteComponent } from './ajuste/ajuste.component';
import { ArticuloComponent } from './articulo/articulo.component';
import { ClienteComponent } from './cliente/cliente.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { PackComponent } from './pack/pack.component';
import { PedidoComponent } from './pedido/pedido.component';
import { PexpressComponent } from './pexpress/pexpress.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { EstadosComponent } from './estados/estados.component';
import { FechasComponent } from './fechas/fechas.component';
import { QuillModule } from 'ngx-quill';
import { ExpressMensajeComponent } from './express-mensaje/express-mensaje.component';
import { VerMensajeComponent } from './ver-mensaje/ver-mensaje.component';
import { ImagenComponent } from './imagen/imagen.component';
import { ArticuloSelectComponent } from './articulo-select/articulo-select.component';
import { PromocionComponent } from './promocion/promocion.component';
import { TipoCitaComponent } from './tipo-cita/tipo-cita.component';
import { NotificacionComponent } from './notificacion/notificacion.component';
import { ClientenrcComponent } from './clientenrc/clientenrc.component';

const modal = [
                AjusteComponent,
                ArticuloComponent,
                ClienteComponent,
                EmpresaComponent,
                PackComponent,
                PedidoComponent,
                PexpressComponent,
                UsuarioComponent,
                EstadosComponent,
                FechasComponent,
                ExpressMensajeComponent,
                VerMensajeComponent,
                ImagenComponent,
                ArticuloSelectComponent,
                PromocionComponent,
                TipoCitaComponent,
                NotificacionComponent,
                ClientenrcComponent
              ];

@NgModule({
  declarations: modal,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    PipesModule,
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ list: 'ordered'}, { list: 'bullet' }],
          [{ script: 'sub'}, { script: 'super' }],      // superscript/subscript
          [{ indent: '-1'}, { indent: '+1' }],          // outdent/indent
          [{ direction: 'rtl' }],                         // text direction

          [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'],                                         // remove formatting button

          ['link', 'image']                         // link and image, video
        ]
      }
    }),
  ],
  exports: modal
})
export class ModalModule { }
