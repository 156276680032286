import { Injectable } from '@angular/core';
import { Angular2PrestaService, Angular2PrestaQuery } from 'angular2-presta';
import { UsuarioPS } from '../interfaces/ps.interface';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Cliente, ClienteResp } from '../interfaces/clientes.interface';
import { environment } from 'src/environments/environment';
import { GenaralDataService } from './genaral-data.service';


const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class PrestashopService {

  constructor(private a2pservice: Angular2PrestaService,
    private http: HttpClient,
    private genaralDataService: GenaralDataService,
    private storage: Storage) { }

  header = {
    token: ''
  };

  validos: any[] = [];

  private async cargarHeader() {
    this.header.token = await this.storage.get('token');
  }


  getUsuarios() {
    const query: Angular2PrestaQuery = {
      resource: 'addresses',
      filter: {
        deleted: '0',

      }
    };
    return new Promise<UsuarioPS[]>(resolve => {
      this.a2pservice.get(query).subscribe((data: UsuarioPS[]) => {
        resolve(data);
      });
    });
  }

  getGrupos() {
    const query: Angular2PrestaQuery = {
      resource: 'groups'
    };
    return new Promise<UsuarioPS[]>(resolve => {
      this.a2pservice.get(query).subscribe((data: UsuarioPS[]) => {
        resolve(data);
      });
    });
  }

  async cargarClientes() {
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.get(`${URL}/clientes`, { headers: this.header }).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  existeUsuario(email: string) {
    const query: Angular2PrestaQuery = {
      resource: 'customers',
      filter: {
        email
      }
    };
    return new Promise<boolean>(resolve => {
      this.a2pservice.get(query).subscribe((data: UsuarioPS[]) => {
        if (data) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getUser(id: number) {
    const query: Angular2PrestaQuery = {
      resource: `customers`,
      filter: {
        id,
        deleted: 0
      }
    };
    return new Promise<any>(resolve => {
      this.a2pservice.get(query).subscribe((data: any) => {
        if (data) {          
          resolve(data[0]);
        }
        resolve(undefined);
      });
    });
  }

  async nuevoCliente(cliente: Cliente) {
    await this.cargarHeader();
    return new Promise<ClienteResp>(resolve => {
      this.http.post(`${URL}/clientes-ps/`, { cliente }, { headers: this.header }).subscribe(async (resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  async uplodaCliente(cliente: Cliente) {
    await this.cargarHeader();    
    return new Promise<any>(async resolve => {
      this.http.put(`${URL}/cliente-upload-ps/${cliente._id}`, {}, { headers: this.header }).subscribe((resp: ClienteResp) => {
        resolve(resp);
      })
    });
  }

  async sincUsuarios() {
    return new Promise<any>(async resolve => {
      const appUser = await this.cargarClientes();
      if (!appUser.ok) {
        resolve({
          ok: false,
          error: 'No se han obtenido datos desde la base de datos, contacte con soporte técnico'
        });
      }

      const userPs = await this.getUsuarios();
      if (!userPs) {
        resolve({
          ok: false,
          error: 'No se han obtenido datos desde la tienda, contacte con soporte técnico'
        });
      } else {
        let valido = 0;
        let leido = 0;
        userPs.forEach(async (ups: any) => {
          const user = await this.getUser(ups.id_customer);
          if (user) {
            let idState: any;
            if (ups.id_state) {
              const resp: any = await this.genaralDataService.getProvincia(ups.id_state);
              if (resp.ok) {
                idState = resp.provincia;
                const cliente: Cliente = {
                  idPs: user.id,
                  nombre: user.firstname,
                  apellido1: user.lastname,
                  email: user.email,
                  nif: ups.dni,
                  localidad: ups.city || ' ',
                  provincia: idState || undefined,
                  telefono: ups.phone_mobile, 
                  direccion: ups.address1 || ' ',
                  cp: ups.postcode || 0
                };
                console.log(cliente);
                const save = await this.nuevoCliente(cliente);
                if (save.ok) {
                  valido++;
                }
              }
            }
          }
          leido++;
          if (leido === userPs.length) {
            resolve({
              ok: true,
              mensaje: 'Proceso terminado'
            });
          }
        });
      }
    });
  }
}
