import { Notificacion } from './../../interfaces/notificacion.interface';
import { ModalController } from '@ionic/angular';
import { PreferenciasPromoComponent } from './../../components/preferencias-promo/preferencias-promo.component';
import { Preferencias } from './../../interfaces/tarjeta.interface';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss'],
})
export class NotificacionComponent implements OnInit {
  @Input()notificacion: Notificacion;
  @Input()tipo: string;
  interes: boolean;
  familia: boolean;
  pref: Preferencias = {interes: {}, familia: {}, porque: {}};
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    if (!this.notificacion){
      this.notificacion = {};
    }
  }

  async peferencias(){
    const modal = await this.modalCtrl.create({
      component: PreferenciasPromoComponent,
      cssClass: '',
      backdropDismiss: false,
      mode: 'ios',
      componentProps: {
        preferencias: this.pref
      }
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data){
      if (data.interes){
        this.interes = true;
      }
      if (data.familia){
        this.familia = true;
      }
      this.pref = data;
    }

  }
  crearNotificacion(){
    this.modalCtrl.dismiss({not: this.notificacion, tipo: this.tipo, preferencias: this.pref});
  }

}
