import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Cliente } from '../../interfaces/clientes.interface';
import { GraficoComponent } from '../grafico/grafico.component';
import { SaludService } from '../../services/salud.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { LoadingController, AlertController } from '@ionic/angular';
import { Azucar, Tension, Peso, Colesterol } from '../../interfaces/salud.interface';

@Component({
  selector: 'app-salud',
  templateUrl: './salud.component.html',
  styleUrls: ['./salud.component.scss'],
})
export class SaludComponent implements OnInit {
  @Input()cliente: Cliente;
  @ViewChild('datosPeso', { static: false }) datosPeso: GraficoComponent;
  @ViewChild('datosAzucar', { static: false }) datosAzucar: GraficoComponent;
  @ViewChild('datosColesterol', { static: false }) datosColesterol: GraficoComponent;
  @ViewChild('datosTension', { static: false }) datosTension: GraficoComponent;
  constructor(private saludService: SaludService,
              private ui: UiSereviceService,
              public loadingController: LoadingController,
              private alertCtrl: AlertController,
              ) { }
  titulo = 'Salud de cliente ';
  azucar: Azucar[] = [];
  tension: Tension[] = [];
  peso: Peso[] = [];
  colesterol: Colesterol[] = [];
  load = true;

  async ngOnInit() {
    if (this.cliente){
      this.titulo = this.titulo + ' ' + this.cliente.nombre + ' ' + this.cliente.apellido1;
    }
    this.loading();
  }

  async cargarDatos() {
    const respAz = await this.saludService.obtenerSaludAzucar(this.cliente._id);
    if (respAz.ok) {
      this.azucar = respAz.azucar.azucar;
    } else {
      this.ui.alertError(respAz.error);
    }
    const respPe = await this.saludService.obtenerSaludPeso(this.cliente._id);
    if (respPe.ok) {
      this.peso = respPe.peso.peso;
    } else {
      this.ui.alertError(respPe.error);
    }
    const respCole = await this.saludService.obtenerSaludColesterol(this.cliente._id);
    if (respCole.ok){
      this.colesterol = respCole.colesterol.colesterol;
    } else {
      this.ui.alertError(respCole.error);
    }
    const respTen = await this.saludService.obtenerSaludTension(this.cliente._id);
    if (respTen.ok){
      // tslint:disable-next-line: no-string-literal
      this.tension = respTen.tension['tension'];
    }
  }

  async loading(){
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Espere por favor...',
      duration: 1000
    });
    await loading.present();
    await this.cargarDatos();
    const { role, data } = await loading.onDidDismiss();
    this.load = false;
  }
  async masAzucar() {
    const alert = await this.alertCtrl.create({
      header: 'Nueva medida de azucar',
      subHeader: 'Añada la nueva medidad tomada de azucar',
      message: 'Nueva medición de azucar en sangre. <br><br><strong>Recuerde tener una alimentación variada.</strong>',
      inputs: [
        {
          name: 'azucar',
          type: 'number',
          placeholder: 'mg dL'
        }],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Guardar',
          handler: async (data) => {
            if (data.azucar) {
              const nuevo = await this.saludService.nuevoAzucar(this.cliente._id, data.azucar);
              if (nuevo) {
                this.ui.presentToast('Nueva medida de azucar añadida');
                this.datosAzucar.pushOne(data.azucar);
                this.cargarDatos();
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async masPeso() {
    const alert = await this.alertCtrl.create({
      header: 'Nueva medida de peso',
      subHeader: 'Añada la nueva medidad tomada de peso',
      message: 'Nueva medición de peso. <br><br><strong>Recuerde tener una alimentación variada.</strong>',
      inputs: [
        {
          name: 'peso',
          type: 'number',
          placeholder: 'Kg'
        }],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Guardar',
          handler: async (data) => {
            if (data.peso) {
              const nuevo = await this.saludService.nuevoPeso(this.cliente._id, data.peso);
              if (nuevo) {
                this.ui.presentToast('Nueva medida de peso añadida');
                this.datosPeso.pushOne(data.peso);
                this.cargarDatos();
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async masColesterol(){
    const alert = await this.alertCtrl.create({
      header: 'Nueva medida de colesterol',
      subHeader: 'Añada la nueva medidad tomada de colesterol',
      message: 'Nueva medición de colesterol. <br><br><strong>Recuerde tener una alimentación variada.</strong>',
      inputs: [
        {
          name: 'colesterol',
          type: 'number',
          placeholder: 'mg dL'
        }],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Guardar',
          handler: async (data) => {
            if (data.colesterol) {
              const nuevo = await this.saludService.nuevoColesterol(this.cliente._id, data.colesterol);
              if (nuevo) {
                this.ui.presentToast('Nueva medida de colesterol añadida');
                this.datosColesterol.pushOne(data.colesterol);
                this.cargarDatos();
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async masTension(){
    const alert = await this.alertCtrl.create({
      header: 'Nueva medida de tensión',
      subHeader: 'Añada la nueva medidad tomada de tension',
      message: 'Nueva medición de tensión. <br><br><strong>Recuerde tener una alimentación variada.</strong>',
      inputs: [
        {
          name: 'dia',
          type: 'number',
          placeholder: 'DIA mg dL'
        },
        {
          name: 'sys',
          type: 'number',
          placeholder: 'SYS mg dL'
        }],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Guardar',
          handler: async (data) => {
            if (data.dia) {
              const nuevo = await this.saludService.nuevoTension(this.cliente._id, data);
              if (nuevo) {
                this.ui.presentToast('Nueva medida de tensión añadida');
                this.datosTension.pushOne(data);
                this.cargarDatos();
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }
}
