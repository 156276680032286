import { UsuarioComponent } from './../../modal/usuario/usuario.component';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, NavController, ModalController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { Usuario } from '../../interfaces/usuario.interface';
import { UsuarioService } from '../../services/usuario.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { Global } from '../../global';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input()titulo: string;
  usuario: Usuario = Global.usuario;
  constructor( private alertController: AlertController,
               private loginService: LoginService,
               private modalCtrl: ModalController,
               private usuarioService: UsuarioService,
               private ui: UiSereviceService,
               private navCtrl: NavController
    ) { }

  async ngOnInit() {
  }

  async salir(){
    const alert = await this.alertController.create({
      header: '¡Advertencia!',
      message: '<strong>¿Desea salir de la aplicación?</strong>',
       buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Salir',
          handler: async () => {
            const lg = await this.loginService.loguout();
            if (lg){
              this.navCtrl.navigateForward('/');
              window.location.reload();
            }
          }
        }
      ]
    });

    await alert.present();
  }


  async edita(){
    const modal = await this.modalCtrl.create({
      component: UsuarioComponent,
      cssClass: 'usuario-modal',
      backdropDismiss: false,
      mode: 'ios',
      componentProps: {
        usuario: this.usuario
      }
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data){
      const resp = await this.usuarioService.actualizaUario(data);
      if (resp.ok){
        this.usuario = resp.usuario;
        Global.usuario = this.usuario;
        this.ui.presentToast('Usuario actualizado');
      } else {
        this.ui.alertError(resp.error);
      }
    }
  }

}
