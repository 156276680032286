import { Pipe, PipeTransform } from '@angular/core';
import { Promocion } from '../interfaces/promocion.interface';

@Pipe({
  name: 'promocion'
})
export class PromocionPipe implements PipeTransform {

  transform(promociones: Promocion[], txt: string): Promocion[] {
    let promo: Promocion[];
    if (txt){
      txt = txt.toLowerCase();
      promo = promociones.filter( p => {
        return p.titulo.toLowerCase().includes(txt) || p.texto?.toLowerCase().includes(txt);
      });
      console.log(promo);
      return promo;
    } else {
      return promociones;
    }
  }
}
