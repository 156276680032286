import { Component, Input, OnInit } from '@angular/core';
import { Mensaje } from '../../interfaces/express.interface';

@Component({
  selector: 'app-ver-mensaje',
  templateUrl: './ver-mensaje.component.html',
  styleUrls: ['./ver-mensaje.component.scss'],
})
export class VerMensajeComponent implements OnInit {

  @Input()mensaje: Mensaje;

  constructor() { }

  ngOnInit() {}

}
