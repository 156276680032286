import { Pipe, PipeTransform } from '@angular/core';
import { Usuario } from '../interfaces/usuario.interface';

@Pipe({
  name: 'usuario'
})
export class UsuarioPipe implements PipeTransform {

  transform(usuarios: Usuario[], txt: string): Usuario[] {
    let us;
    if (!txt){
      return usuarios;
    } else {
      txt = txt.toLowerCase();
      us = usuarios.filter( u => {
        return u.nombre.toLowerCase().includes(txt) || u.apellido1.toLowerCase().includes(txt) ||
               u.apellido2?.toLowerCase().includes(txt) || u.email.toLowerCase().includes(txt);
      });
      return us;
    }
  }

}
