import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ExpressResp, Mensaje, Express } from '../interfaces/express.interface';


const URL = environment.bakendUrl;



@Injectable({
  providedIn: 'root'
})
export class ExpressService {

  constructor(private storage: Storage, private http: HttpClient) { }
  page = 0;
  header = {
    token: ''
  };

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargarExpress(){
    this.page++;
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.get(`${URL}/express?pag=${this.page}`, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }

  async cargarClientesExpress(){
    this.page++;
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.get(`${URL}/clientesnrc`, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }

  async filtroExpress(sol: any){
    this.page++;
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.put(`${URL}/filtros/express?pag=${this.page}`, sol, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }


  async cargarExpressXls(){
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.get(`${URL}/expressXls`, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }

  async filtroExpressXls(sol: any){
    this.page++;
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.put(`${URL}/filtros/expressXls`, sol, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }

  async añadirMensaje(idPExpres: string, men: Mensaje){
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.put(`${URL}/express/mensaje/${idPExpres}`, men, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }

  async cambioEstado(pedido: Express){
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.put(`${URL}/express/estado/${pedido._id}`,
      {estado: pedido.estado}, {headers: this.header}).subscribe((resp: ExpressResp) => {
          resolve(resp);
      });
    });
  }

  async nuevoPedidoExpress(pedido: Express){
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.post(`${URL}/express-office`, {pedido}, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }

  async actualizarPedidoExpress(pedido: Express){
    await this.cargarHeader();
    return new Promise<ExpressResp>(resolve => {
      this.http.put(`${URL}/express-office`, {pedido}, {headers: this.header}).subscribe((resp: ExpressResp) => {
        resolve(resp);
      });
    });
  }

}
