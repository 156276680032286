import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PacksResp, Pack } from '../interfaces/packs.inteface';

const URL = environment.bakendUrl;

@Injectable({
  providedIn: 'root'
})
export class PackService {

  constructor(private storage: Storage, private http: HttpClient) { }

  header = {
    token: ''
  };

  pag = 0;

  private async cargarHeader(){
    this.header.token = await this.storage.get('token');
  }

  async cargarPacks() {
    this.pag++;
    await this.cargarHeader();
    return new Promise<PacksResp>(resolve => {
      this.http.get(`${URL}/packs`, {headers: this.header}).subscribe((resp: PacksResp) => {
        resolve(resp);
      });
    });
  }

  async nuevoPack(pack: Pack){
    await this.cargarHeader();
    return new Promise<PacksResp>(resolve => {
      this.http.post(`${URL}/packs`, pack, {headers: this.header}).subscribe((resp: PacksResp) => {
        resolve(resp);
      });
    });
  }

  async actualizaPack(pack: Pack){
    await this.cargarHeader();
    return new Promise<PacksResp>(resolve => {
      this.http.put(`${URL}/packs/${pack._id}`, pack, {headers: this.header}).subscribe((resp: PacksResp) => {
        resolve(resp);
      });
    });
  }

  async eliminaPack(packId: string){
    await this.cargarHeader();
    return new Promise<PacksResp>(resolve => {
      this.http.delete(`${URL}/packs/${packId}`, {headers: this.header}).subscribe((resp: PacksResp) => {
        resolve(resp);
      });
    });
  }

}
