import { Component, Input, OnInit } from '@angular/core';
import { Express, Mensaje } from '../../interfaces/express.interface';
import { ModalController } from '@ionic/angular';
import { ExpressMensajeComponent } from '../express-mensaje/express-mensaje.component';
import { ExpressService } from '../../services/express.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { VerMensajeComponent } from '../ver-mensaje/ver-mensaje.component';
import { EmailService } from '../../services/email.service';
import { Pedido } from '../../interfaces/pedidos.interface';

@Component({
  selector: 'app-pexpress',
  templateUrl: './pexpress.component.html',
  styleUrls: ['./pexpress.component.scss'],
})
export class PexpressComponent implements OnInit {

  @Input()pexpress: Express;
  estadoModificado: boolean;

  constructor( private modalCtrl: ModalController,
               private expressService: ExpressService,
               private es: ExpressService,
               private ui: UiSereviceService,
               private emailService: EmailService) { }

  ngOnInit() {
  }

  guardar(){
    this.modalCtrl.dismiss({pexpress: this.pexpress, modificado: this.estadoModificado});
  }
  async nuevoMensaje(pexpress: Express){
    const modal = await this.modalCtrl.create({
      component: ExpressMensajeComponent,
      cssClass: 'mensaje-modal',
      backdropDismiss: false,
      componentProps: {
        pexpress
      }
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    const men: Mensaje = {
      titulo: data.titulo,
      texto: data.mensaje,
      fecha: new Date(),
      leido: false
    };
    const resp = await this.es.añadirMensaje(pexpress._id, men);
    if (resp.ok){
      pexpress.mensaje.unshift(men);
      const envio: any = await this.emailService.sernMensajeEmail(men, pexpress.cliente._id, pexpress._id);
      if (!envio.ok){
        this.ui.alertError('Error al enviar por correo el mensaje');
      }
    } else {
      this.ui.alertError(resp.error);
    }

  }

  async verMensaje(mensaje: Mensaje){
    const modal = await this.modalCtrl.create({
      component: VerMensajeComponent,
      cssClass: 'vmensaje-modal',
      backdropDismiss: false,
      componentProps: {
        mensaje
      }
    });

    await modal.present();
  }

  cambioEstado(){
    this.estadoModificado = true;
  }

  async separar(){
    const art = this.pexpress.pedido.filter( p => {
      return p.cambio;
    });
    this.pexpress.pedido = this.pexpress.pedido.filter(p => {
      return !p.cambio;
    });
    let pedido: Express;
    
    if(this.pexpress.cliente){
      console.log("esta en cliente");
      pedido = {
        cliente: this.pexpress.cliente,
        pedido: art,
        eliminado: false
      };
    }else{
      console.log("No registrado");
      pedido = {
        nombreCliente: this.pexpress.nombreCliente,
        mailCliente: this.pexpress.mailCliente,
        telefono: this.pexpress.telefono,
        pedido: art,
        eliminado: false
      };
    }

    const resp = await this.expressService.nuevoPedidoExpress(pedido);
    if (!resp.ok){
      this.ui.alertError(resp.error);
      this.pexpress.pedido.push(...art);
      return;
    }
    const update = await this.expressService.actualizarPedidoExpress(this.pexpress);
    if (!update.ok){
      this.ui.alertError(update.error);
      return;
    }
    this.ui.presentToast('Pedido separado con éxito');
  }

  async eliminar(){
    this.pexpress.pedido = this.pexpress.pedido.filter(p => {
      return !p.cambio;
    });
    const update = await this.expressService.actualizarPedidoExpress(this.pexpress);
    if (!update.ok){
      this.ui.alertError(update.error);
      return;
    }
    this.ui.presentToast('Pedido separado con éxito');
  }

  enable(): boolean{
    let enable = false;
    this.pexpress.pedido.forEach( p => {
      if (p.cambio){
        enable = true;
      }
    });
    return enable;
  }

}
