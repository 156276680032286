import { Component, Input, OnInit } from '@angular/core';
import { Promocion } from 'src/app/interfaces/promocion.interface';
import { ModalController } from '@ionic/angular';
import { PreferenciasPromoComponent } from '../../components/preferencias-promo/preferencias-promo.component';
import { Preferencias } from 'src/app/interfaces/tarjeta.interface';
import { PromocionesService } from '../../services/promociones.service';

@Component({
  selector: 'app-promocion',
  templateUrl: './promocion.component.html',
  styleUrls: ['./promocion.component.scss'],
})
export class PromocionComponent implements OnInit {
  @Input() promocion: Promocion;
  @Input()tipo: string;
  interes: boolean;
  familia: boolean;
  pref: Preferencias = {interes: {}, familia: {}, porque: {}};
  constructor(private modalCtrl: ModalController, private promocionesService: PromocionesService) { }

  ngOnInit() {
    if (!this.promocion){
      this.promocion = {};
    }
    console.log(this.tipo);
  }

  async crearPromo(){
    // const resp = await this.promocionesService.anadirPromo(this.promocion, this.tipo, this.pref);
    this.modalCtrl.dismiss({promo: this.promocion, tipo: this.tipo, preferencias: this.pref});
  }

  async peferencias(){
    const modal = await this.modalCtrl.create({
      component: PreferenciasPromoComponent,
      cssClass: '',
      backdropDismiss: false,
      mode: 'ios',
      componentProps: {
        preferencias: this.pref
      }
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data){
      if (data.interes){
        this.interes = true;
      }
      if (data.familia){
        this.familia = true;
      }
      this.pref = data;
    }

  }

}
