import { Pipe, PipeTransform } from '@angular/core';
import { Pack } from '../interfaces/packs.inteface';

@Pipe({
  name: 'pack'
})
export class PackPipe implements PipeTransform {

  transform(packs: Pack[], texto: string): Pack[] {
    let pk: Pack[];
    if (!texto){
      return packs;
    } else {
      texto = texto.toLowerCase();
      pk = packs.filter(p => {
        return p.nombre.toLowerCase().includes(texto) || p.descripcion.toLowerCase().includes(texto);
      });
      return pk;
    }
  }
}
