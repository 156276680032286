import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-fechas',
  templateUrl: './fechas.component.html',
  styleUrls: ['./fechas.component.scss'],
})
export class FechasComponent implements OnInit {

  intervalo: boolean;
  datei: Date;
  dateo: Date;

  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {}

  buscar(){
    this.modalCtrl.dismiss({fechaini: this.datei, fechafin: this.dateo});
  }

}
