import { Pipe, PipeTransform } from '@angular/core';
import { errorMonitor } from 'events';
import { Empresa } from '../interfaces/empresa.interface';

@Pipe({
  name: 'buscarEmpresa'
})
export class BuscarEmpresaPipe implements PipeTransform {

  transform(empresas: Empresa[], text: string): Empresa[] {
    if (text) { text = text.toLowerCase(); }
    if (!text){
      return empresas;
    } else {
      return empresas.filter( emp => {
        return emp.nombreComercial.toLowerCase().includes(text) ||
               emp.nombreFiscal.toLowerCase().includes(text) ||
               emp.cif.toLowerCase().includes(text) ||
               emp.provincia.name.toLowerCase().includes(text) ||
               emp.localidad.toLowerCase().includes(text) ||
               emp.email.toLowerCase().includes(text) ||
               emp.cp.toString().includes(text) ||
               emp.telefono.toString().includes(text) ;
      });
    }
  }

}
