import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ClienteService } from '../../services/cliente.service';
import { Cliente } from '../../interfaces/clientes.interface';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
})
export class ClienteComponent implements OnInit {

  constructor( private modalCtrl: ModalController,
               private clienteService: ClienteService,
               private ui: UiSereviceService) { }

  clientes: Cliente[] = [];
  buscarTxt = '';

  async ngOnInit() {
    const resp = await this.clienteService.cargarClientes('activos');
    if (resp.ok){
      this.clientes = resp.clientes;
    } else {
      this.ui.alertError(resp.error);
    }
  }

  buscar(event){
    this.buscarTxt = event.detail.value;
  }
  select(cliente: Cliente){
    this.modalCtrl.dismiss(cliente);
  }

}
